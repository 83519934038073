/* Message.css */
.prose pre {
    background-color: rgb(39, 39, 39) !important; /* Override background color */
    color: #464646 !important; /* Ensure the text is readable on the dark background */
    padding: 0;
    overflow-x: unset;
    border-radius: 12px;
    margin: 0;
  }
  
  .prose a { /* Specifically target links */
    color: #a78bfa; /* Example: a purple color for links, change as needed */
  }
  
  .prose strong,
  .prose b { /* Bold text */
    color: #464646;
    font-weight: bolder;
  }
  
  .prose em { /* Italicized text */
    color: #464646;
  }
  
  .prose h1,
  .prose h2,
  .prose h3,
  .prose h4,
  .prose h5,
  .prose h6 { /* Headings */
    color: #464646;
  }
  
  .prose blockquote { /* Blockquotes */
    color: #464646;
  }
  
  .prose ol,
  .prose ul { /* Ordered and unordered lists */
    color: #464646;
  }
  
  .prose code { /* Inline code */
    color: #464646;
  }